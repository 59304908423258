import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const basics = apiPath.basics;

export function getContractorTemplateType(params) {
  return axios.get(`${basics}/select/contractor/template/type`, { params });
}

export function getSelectContractorsNames(params) {
  return axios.get(`${basics}/select/contractors`, { params });
}

// 查询评价状态下拉
export function getEvaluateStatus(params) {
  return axios.get(`${basics}/select/evaluate/status`, { params });
}

export const getEvaluateList = params => {
  return axios.get(`${basics}/page/contractor/record`, { params });
};

export function getContractorTaskInfo(params) {
  return axios.get(`${basics}/info/contractor/task/${params.id}`);
}

export function getContractorScore(params) {
  return axios.get(`${basics}/list/contractor/score`, { params });
}

export function saveContractorResult(id, data) {
  return axios.post(`${basics}/add/contractor/result/${id}`, data);
}

export function submitContractorResult(id, data) {
  return axios.post(`${basics}/update/contractor/result/${id}`, data);
}
