<template>
  <div class="evaluate-list">
    <slot name="header" />
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      v-bind="$attrs"
      @load="getList"
    >
      <div
        v-for="(item, idx) in list"
        :key="idx"
        class="evaluate-item"
        @click="gotoDetails(item)"
      >
        <div class="evaluate-item-header">
          {{ item.name }}
        </div>

        <div class="evaluate-item-body">
          <div class="evaluate-item-row">
            <span class="evaluate-item-text">
              <van-icon class-prefix="iconfont" name="qiye" />
              {{ item.contractorName }}
            </span>
          </div>
          <div class="evaluate-item-row">
            <span class="evaluate-item-text">
              <span class="evaluate-item-type">{{ item.typeName }}</span
              >{{ item.evaluateEndDate }} (截止时间)
            </span>
          </div>

          <div v-if="StatusIcon[item.status]" class="evaluate-item-status">
            <van-icon
              :style="{ color: StatusIcon[item.status].color }"
              :name="StatusIcon[item.status].icon"
              class-prefix="iconfont"
              class="evaluate-item-icon"
            />
          </div>
        </div>
      </div>
    </LoadListView>
  </div>
</template>

<script>
import { StatusIcon } from "../../utils/evaluate";
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    StatusIcon: () => StatusIcon
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoDetails(item) {
      this.$router.push({
        path: "/contractor-evaluate/" + item.id,
        query: { taskId: item.taskId }
      });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.evaluate {
  &-item {
    padding: 10px 16px;
    background-color: #fff;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8f97;
    line-height: 14px;
    border-bottom: 8px solid #eceef2;
  }

  &-item-body {
    position: relative;
  }

  &-item-header {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 16px;
  }

  &-item-row {
    margin-top: 8px;
  }

  &-item-type {
    display: inline-flex;
    align-items: center;
    padding: 3px 8px;
    background: #1676ff;
    border-radius: 2px;
    margin-right: 12px;

    font-size: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #ffffff;
  }

  &-item-status {
    width: 44px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &-item-icon {
    font-size: 44px;
  }
}
</style>
