<template>
  <div class="switching-add has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">考核评估记录</div>
    </van-nav-bar>

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-form
        ref="form"
        class="content-box"
        input-align="right"
        validate-trigger="onSubmit"
      >
        <!-- 评估任务名称 -->
        <van-field label="评估任务名称">
          <template #input>
            {{ form.name }}
          </template>
        </van-field>
        <!-- 评价类型 -->
        <van-field label="评价类型">
          <template #input>
            {{ form.typeName }}
          </template>
        </van-field>
        <!-- 评估模板名称 -->
        <van-field label="评估模板名称">
          <template #input>
            {{ form.templateInstanceName }}
          </template>
        </van-field>
        <!-- 项目名称/编号 -->
        <van-field label="项目名称/编号">
          <template #input>
            {{ form.typeDataName }}
          </template>
        </van-field>
        <!-- 承包商名称 -->
        <van-field label="承包商名称">
          <template #input>
            {{ form.contractorName }}
          </template>
        </van-field>
        <!-- 评价人 -->
        <van-field label="评价人">
          <template #input>
            {{ form.evaluatePersons }}
          </template>
        </van-field>
        <!-- 评价截止时间 -->
        <van-field label="评价截止时间">
          <template #input>
            {{ form.evaluateEndDate }}
          </template>
        </van-field>

        <div class="evaluate-list">
          <div class="evaluate-title">评价内容</div>
          <div
            v-for="(item, index) in tableData"
            :key="index"
            class="evaluate-item"
          >
            <div class="evaluate-item-box">
              <div
                v-for="(lv, lvIndex) in levelList"
                :key="`row${index}-${lvIndex}`"
                class="evaluate-item-row"
              >
                <div class="evaluate-item-row-label">{{ lv.name }}:</div>
                <div class="evaluate-item-row-value">
                  {{ item[lv.level] && item[lv.level].name }}
                </div>
              </div>
              <div class="evaluate-item-row">
                <div class="evaluate-item-row-label">最高分值:</div>
                <div class="evaluate-item-row-value">{{ item.score }}</div>
              </div>
            </div>
            <van-field
              :value="getScore(item.id)"
              :readonly="!editable"
              label-width="16vw"
              class="evaluate-item-input"
              :class="{
                'is-readonly': !editable,
                'is-red': !editable && getScore(item.id) !== item.score
              }"
              input-align="left"
              label="我的评分:"
              type="number"
              placeholder="请输入我的评分"
              :error="false"
              :rules="[
                {
                  required: true,
                  message: `请输入我的评分`,
                  trigger: 'onChange'
                },
                {
                  validator: scoreValidator(item.score),
                  message: '请输入最高分值以内的分数',
                  trigger: 'onChange'
                }
              ]"
              @input="setScore(item.id, $event)"
            />
            <div class="van-hairline--bottom evaluate-item-line"></div>
          </div>
        </div>

        <!-- 其他 -->
        <van-field
          v-model="valItem.content"
          :readonly="!editable"
          input-align="left"
          rows="3"
          autosize
          label="其他"
          type="textarea"
        />
        <!-- 总得分 -->
        <van-field label="总得分">
          <template #input>
            {{ totalScore }}
          </template>
        </van-field>
        <!-- 评价结论 -->
        <van-field clickable @click="showDetail">
          <template #label>
            评价结论 <van-icon name="info" color="#D9DBE1" />
          </template>
          <template #input>
            {{ valItem.result }}
          </template>
        </van-field>
        <!-- 评价结论 -->
        <van-field label="评价时间">
          <template #input>
            {{ valItem.evaluateTime }}
          </template>
        </van-field>
      </van-form>
    </LoadView>

    <BottomFixedBox v-if="editable">
      <van-button class="confirm-btn" round size="small" @click="cancel"
        >取消</van-button
      >
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="onSave()"
        >保存</van-button
      >
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="onsubmit()"
        >提交</van-button
      >
    </BottomFixedBox>

    <van-dialog
      v-model="show"
      title="标题"
      :show-confirm-button="false"
      close-on-click-overlay
    >
      <span slot="title" class="detail-title">评价结论详情</span>
      <div class="detail-list">
        <div
          v-for="(item, index) in settingList"
          :key="index"
          class="detail-row"
        >
          <span class="detail-label">{{ item.itemName }}</span>
          <span class="detail-value"
            >{{ item.minValue }} ＜ - ≤ {{ item.maxValue }}</span
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import BottomFixedBox from "@/components/general/BottomFixedBox";

import { LOAD_STATUS } from "@/components/loadview/utils";
import { Dialog } from "vant";
import {
  getContractorTaskInfo,
  getContractorScore,
  saveContractorResult,
  submitContractorResult
} from "../api";

export default {
  components: {
    LoadView,
    BottomFixedBox
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      form: {},
      valueList: [],
      show: false
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    itemList() {
      return this.form.itemList || [];
    },
    levelList() {
      return this.form.levelList || [];
    },
    settingList() {
      return this.form.settingList || [];
    },
    tableData() {
      const itemList = this.itemList || [];

      const reslut = [];

      const getList = (list, config = {}) => {
        list.forEach((item, index) => {
          const conf = { ...config };

          conf[item.level] = {
            id: item.id,
            name: item.name,
            sort: index + 1
          };

          if (item.children && item.children.length) {
            getList(item.children, conf);
          } else {
            const cell = {
              id: item.id,
              score: item.score,
              ...conf
            };

            reslut.push(cell);
          }
        });
      };
      getList(itemList);

      return reslut;
    },
    valItem() {
      return this.valueList[0] || {};
    },
    scoreMap() {
      const obj = {};
      const scoreList = this.valItem.scoreList || [];
      scoreList.forEach(item => {
        obj[item.itemId] = item;
      });
      return obj;
    },
    totalScore() {
      const list = this.valItem.scoreList || [];
      if (!list.length) return 0;
      const sum = list.map(i => i.score || 0).reduce((a, b) => a + b);
      return sum || 0;
    },
    editable() {
      return this.valItem.status !== 1;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    cancel() {
      Dialog.confirm({
        message: "数据未保存，确认取消？"
      }).then(() => {
        this.$router.back();
      });
    },
    getData() {
      const { taskId } = this.$route.query;
      getContractorTaskInfo({ id: taskId })
        .then(res => {
          if (!res) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          if (res.type) {
            res.type = res.type + "";
          }
          res.evaluatePersons = res.personList
            ?.map(person => person.label)
            ?.join();
          this.form = res;
          return getContractorScore({
            taskId,
            templateInstanceId: this.form.templateInstanceId,
            userId: this.userInfo.id
          });
        })
        .then(res => {
          this.valueList = res.filter(i => i.userId !== "0");
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    getScore(id) {
      return this.scoreMap[id]?.score;
    },
    setScore(id, val) {
      if (this.scoreMap[id]) {
        const value = !val && val !== 0 ? "" : parseInt(val);
        this.scoreMap[id].score = value;
        this.$nextTick(() => {
          this.updateValue();
        });
      }
    },
    getTotal(list = []) {
      if (!list.length) return 0;
      const sum = list
        .map(i => i.score || 0)
        .reduce((a, b) => parseFloat(a) + parseFloat(b));
      return sum || 0;
    },
    // 计算评价结论
    getStage(score) {
      for (let i = 0; i < this.settingList.length; i++) {
        const item = this.settingList[i];
        if (score > item.minValue && score <= item.maxValue)
          return item.itemName;
      }
    },
    // 页面数据变化时候更新合计数量
    updateValue() {
      this.valueList.forEach(item => {
        item.score = this.getTotal(item.scoreList || []);
        item.result = this.getStage(item.score);
      });
    },
    scoreValidator(maxScore) {
      return score => {
        return score >= 0 && score <= maxScore;
      };
    },
    showDetail() {
      this.show = true;
    },
    async onSave() {
      await this.$refs.form.validate();
      Promise.all(
        this.valueList.map(item =>
          saveContractorResult(this.$route.params.id, {
            ...item,
            templateInstanceId: this.form.templateInstanceId
          })
        )
      ).then(() => {
        this.$toast.success("保存成功");
        this.$router.back();
      });
    },
    async onsubmit() {
      await this.$refs.form.validate();
      Promise.all(
        this.valueList.map(item =>
          submitContractorResult(this.$route.params.id, {
            ...item,
            templateInstanceId: this.form.templateInstanceId
          })
        )
      ).then(() => {
        this.$router.back();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.evaluate {
  &-list {
    border-top: 10px solid #f3f5fa;
  }

  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 22px;
    padding: 12px 16px 0;
  }

  &-item-box {
    margin: 12px 16px 0;
    background: #f5f7fa;
    border-radius: 6px;
    padding: 12px;
  }

  &-item-row {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8f97;
    line-height: 16px;
    display: flex;
    align-items: flex-start;
  }

  &-item-row-label {
    width: 80px;
    flex-shrink: 0;
  }

  &-item-row-value {
    flex-grow: 1;
  }

  &-item-row + &-item-row {
    margin-top: 8px;
  }

  &-item-line {
    margin: 0 16px;
  }

  &-item-input {
    ::v-deep {
      .van-field__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f3961c;
      }

      .van-field__body {
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #f3961c;
        padding-left: 12px;
      }
    }

    &.is-readonly {
      ::v-deep {
        .van-field__body {
          border-color: transparent;
        }
      }
    }
    &.is-red {
      ::v-deep {
        .van-field__control {
          color: red;
        }
      }
    }
  }
}
.detail {
  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242f3b;
    line-height: 22px;
  }

  &-list {
    padding: 16px 16px 20px;
  }

  &-row {
    background: #f5f7fa;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px;
  }

  &-row + &-row {
    margin-top: 8px;
  }

  &-label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8f97;
    line-height: 20px;
  }

  &-value {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 20px;
  }
}
</style>
