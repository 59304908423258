<template>
  <div class="switching-list has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">承包商考核评估</div>
    </van-nav-bar>

    <EvaluateList
      ref="switching-list"
      :show-total-view="true"
      :api="getEvaluateList"
    >
      <template slot="header">
        <EvaluateSearch :data="searchData" @on-search="onSearchMineList" />
      </template>
    </EvaluateList>
  </div>
</template>

<script>
import EvaluateList from "./components/EvaluateList.vue";
import EvaluateSearch from "./components/EvaluateSearch.vue";

import { getEvaluateList } from "../api";

export default {
  components: {
    EvaluateList,
    EvaluateSearch
  },
  data() {
    return {
      searchData: {
        nameLike: "",
        startDate: "",
        endDate: "",
        type: "",
        contractorId: "",
        status: ""
      }
    };
  },
  methods: {
    getEvaluateList(query = {}) {
      return getEvaluateList({ ...this.searchData, ...query });
    },
    onSearchMineList() {
      this.$refs["switching-list"].getList(true);
    }
  }
};
</script>
