<template>
  <div>
    <van-search
      v-model="data.nameLike"
      placeholder="请输入评估任务名称关键字"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <div class="evaluate-search-timepicker">
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="date"
        format="{y}-{m}-{d}"
        style="margin: 0;"
      />
    </div>

    <EvaluateQuery :query="data" @refresh="onSearch" />
  </div>
</template>

<script>
import EvaluateQuery from "./EvaluateQuery.vue";
export default {
  components: {
    EvaluateQuery
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dateTime: {
        start: "",
        end: ""
      }
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.data.startDate = this.formatDate(val.start);
          this.data.endDate = this.formatDate(val.end);
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.data.startDate = "";
          this.data.endDate = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  methods: {
    formatDate(date) {
      return date;
    },
    onSearch() {
      this.$emit("on-search");
    }
  }
};
</script>

<style lang="scss" scoped>
.evaluate-search-timepicker {
  padding: 0px 12px;
}
</style>
